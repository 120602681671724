<template>
  <section id="propertiesList">
    <div class="listHeader">
      <h1 class="pl-3 homeHeader">Properties</h1>
      <div class="grid grid-cols-9 gap-5">
        <div class="col-span-2 pl-3 text-12 pl-3">Name</div>
        <div class="col-span-1 text-12 pl-3">{{ roomLabel }}s</div>
        <div class="col-span-1 text-12 pl-3">Appliances</div>
        <div class="col-span-1 text-12 pl-3">Online</div>
        <div class="col-span-1 text-12 pl-3">Offline</div>
        <div class="col-span-1 text-12 pl-3">Errors</div>
        <div class="col-span-2 text-12 pl-3">Property Manager</div>
      </div>
      <div class="gradient_spacer relative w-full m-0"></div>
    </div>
    <ul>
      <listing
        v-for="property in properties"
        :key="property.name"
        :name="property.name"
        :units="property.units"
        :url="property.url"
        :managerEmail="property.managerEmail"
        :applianceCounts="property.applianceCounts"
        :address="property.address"
      ></listing>
    </ul>
    <div class="absolute bottom-0 right-0 p-5 align-right text-18">
      <button class="is-disabled">ADD PROPERTY</button>
    </div>
  </section>
</template>

<script>
  import listing from '@/components/propertiesHomeListItem.vue';
  import { getDataByHostname } from '../utils/dataUtil';

  export default {
    components: { listing },
    data() {
      return {
        properties: [],
      };
    },
    mounted() {
      const dataByHostName = getDataByHostname();
      this.properties = dataByHostName.properties || [];
    },
    computed: {
      roomLabel: function() {
        return this.$store.getters.propertyLabels('room');
      },
    },
    watch: {},
    methods: {},
  };
</script>
<style lang="scss" scope>
  @import '../styles/typovars';
</style>
