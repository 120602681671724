<template>
  <li @click="propertyClick" class="w-full relative rounded grey p-2 mb-2">
    <div class="grid grid-cols-9 gap-5">
      <div class="col-span-2 pl-3">
        <div class="text-white font-extrabold text-20 mb-0">{{ name }}</div>
        <div class="text-ltgrey text-12 m-0">{{ address }}</div>
      </div>
      <div class="col-span-1 text-white font-extrabold text-20 border-left my-auto p-2">
        {{ units }}
      </div>
      <div class="col-span-1 text-white font-extrabold text-20 border-left my-auto p-2">
        {{ applianceCounts.total }}
      </div>
      <div class="col-span-1 font-extrabold text-20 text-green border-left my-auto p-2">
        {{ applianceCounts.online }}
      </div>
      <div class="col-span-1 font-extrabold text-20 text-yellow border-left my-auto p-2">
        {{ applianceCounts.offline }}
      </div>
      <div
        class="col-span-1 font-extrabold text-20 border-left my-auto p-2"
        :class="{
          'text-red': applianceCounts.errors > 0,
          'text-ltgrey': applianceCounts.errors == 0,
        }"
      >
        {{ applianceCounts.errors }}
      </div>
      <div class="arrow-cta col-span-2 text-12 text-white border-left my-auto p-2">
        <a class="pointer-events-none">{{ managerEmail }}</a
        ><a :to="url"><span class="right-2"></span></a>
      </div>
    </div>
  </li>
</template>

<script>
  export default {
    props: {
      name: {
        type: String,
        default: 'Orchard Gardens',
      },
      address: {
        type: String,
        default: '123 Main St. City State 12345',
      },
      units: {
        type: Number,
        default: 200,
      },
      applianceCounts: {
        type: Object,
        default: () => ({
          online: 0,
          offline: 0,
          errors: 0,
        }),
      },
      managerEmail: {
        type: String,
        default: 'name@email.com',
      },
      url: {
        type: String,
        default: '',
      },
    },
    components: {},
    data() {
      return {};
    },
    computed: {},
    mounted() {},
    watch: {},
    methods: {
      propertyClick: function() {
        if (this.url) this.$router.push(this.url);
      },
    },
  };
</script>
<style lang="scss" scope>
  @import '../styles/typovars';
  .rounded {
    cursor: pointer;
  }
  .manager {
    // cursor: pointer;
    position: relative;
    &:after {
      content: '>';
      width: 29px;
      height: 40px;
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translateY(-50%);
      display: inline-block;
      font-family: $sans;
      font-size: 48px;
      color: $yellow;
      letter-spacing: 0;
      line-height: 40px;
      .rounded:hover & {
        color: #fff;
      }
    }
  }
</style>
